import React from "react";
import Head from "../../components/head.js";

import Layout from "../../components/layout";

// import "./about.css"

const Summer2022 = () => {
  return (
    <Layout>
      <Head title="Summer 2022" />
      <h1>Summer 2022</h1>
      <p>Mostly oil pastels</p>
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7178-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7189-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7180-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7179-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7183-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7200-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7196-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7215-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7187-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7217-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7221-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7206-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7194-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7216-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7235-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7239-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7263-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7250-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7243-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7286-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7256-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7274-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7313-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7197-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7293-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7300-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7306-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7328-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7364-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7327-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7331-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659480570/7-31-22%20oil%20pastels/IMG_7351-min.jpg"
        alt="Summer 2022 Benji Friedman Oil Pastel Art"
      />
    </Layout>
  );
};

export default Summer2022;
